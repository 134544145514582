export const requestLogTypes: TypeDefinitions = {
    type_default_action: {
        icon: 'heroicons:information-circle',
        color: 'gray'
    },
    type_user_deleted: {
        icon: 'heroicons:trash',
        color: 'danger'
    },
    type_user_not_deletable: {
        icon: 'heroicons:exclamation-triangle',
        color: 'warning'
    },
    type_tokens_generated: {
        icon: 'heroicons:check-circle-16-solid',
        color: 'success'
    },
}
